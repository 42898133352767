import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgUser32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16 17a7 7 0 1 1 0-14 7 7 0 0 1 0 14m0-2a5 5 0 1 0-.001-10.001A5 5 0 0 0 16 15" /><path d="M10.041 18.667C4.001 21.824 4 28 4 28h24c0-6.013-5.959-9.333-5.959-9.333-6.523 4.285-12 0-12 0m1.233-1.576c.186.146.621.419 1.253.705 2.579 1.166 5.421 1.166 8.417-.801l1.013-.665 1.059.59c.91.507 2.185 1.432 3.462 2.788 2.184 2.319 3.523 5.093 3.523 8.293v2h-28v-2c0-.484.071-1.235.275-2.169.336-1.532.94-3.064 1.884-4.501 1.187-1.807 2.827-3.322 4.956-4.435l1.143-.597 1.016.795z" /></SvgIcon>;
};
const Memo = memo(SvgUser32);
export default Memo;