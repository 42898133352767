import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgService32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16 25.5c5.247 0 9.5-4.253 9.5-9.5S21.247 6.5 16 6.5 6.5 10.753 6.5 16s4.253 9.5 9.5 9.5m0 1.5C9.925 27 5 22.075 5 16S9.925 5 16 5s11 4.925 11 11-4.925 11-11 11" /><path d="M15 12.995c0-.55.444-.995 1-.995.553 0 1 .445 1 .995v9.01c0 .55-.444.995-1 .995-.553 0-1-.445-1-.995zM16 11a1 1 0 1 0 0-2 1 1 0 1 0 0 2" /></SvgIcon>;
};
const Memo = memo(SvgService32);
export default Memo;