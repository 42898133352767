import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgSearch32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M12.581 22.812c-5.132 0-9.308-4.083-9.308-9.102s4.176-9.104 9.308-9.104 9.307 4.084 9.307 9.104-4.174 9.102-9.307 9.102m18.086 5.774-8.941-8.474a10.83 10.83 0 0 0 2.101-6.402c0-6.089-5.044-11.043-11.246-11.043S1.334 7.621 1.334 13.71c0 6.089 5.046 11.042 11.247 11.042 3.065 0 5.846-1.213 7.877-3.172l8.876 8.413z" /></SvgIcon>;
};
const Memo = memo(SvgSearch32);
export default Memo;