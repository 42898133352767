import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgNewsletter32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M29.875 13.156v-.032q0-.031-.016-.063t-.016-.094q-.031-.031-.047-.094t-.016-.094l-.063-.063q-.032-.032-.031-.063-.031-.063-.063-.109t-.094-.078l-.016-.016-.016-.016-2.375-1.906V9.309q0-.438-.297-.719t-.703-.281h-1.344q0-.031-.016-.031t-.016-.031l-6.563-5.406q-.906-.781-2.141-.781t-2.109.781L7.495 8.31H6.089q-.406 0-.703.281t-.297.719v1.219l-2.406 1.906-.016.016-.016.016q-.031.031-.063.078t-.063.078-.063.078-.031.078q-.031.031-.047.078t-.016.109q0 .031-.016.063t-.016.094v.032q0 .031-.016.031t-.016.031V29.53q0 .406.297.703t.703.297h25.563q.406 0 .703-.297t.297-.703V13.155zm-2.781-.093.406.313-.188.188-.219.219v-.719zM15.219 4.344q.344-.313.844-.297t.844.328l4.781 3.938H10.563zm-8.156 5.937h18.063v5.281q-.844.719-1.938 1.688T21 19.156q-1.063.938-2.016 1.75t-1.516 1.281q-.781.594-1.391.484t-1.016-.422q-.344-.25-1.281-1.047t-2.125-1.797q-1.156-1-2.406-2.063t-2.188-1.875v-5.188zm-1.969 2.782v.688q-.125-.094-.219-.203t-.156-.203l.375-.281zm-.781 2.656q.281.219 1.031.875t1.719 1.5l2.109 1.781q1.109.937 2.078 1.75l-6.938 5.5zm1.375 12.844 7.094-5.625q.375.313.641.531t.422.344q.563.438 1.156.641t1.188.203q.656 0 1.297-.234t1.203-.703q.188-.125.406-.313t.438-.375l7 5.531zm22.218-1.438-6.844-5.406 2.047-1.781 2.047-1.781q.969-.844 1.703-1.5t1.047-.906v11.375zM11.25 14.188h9.719q.406 0 .688-.281t.281-.688-.281-.703-.688-.297H11.25q-.406 0-.703.297t-.297.703.297.688.703.281m10.688 2.531q0-.406-.281-.688t-.688-.281H11.25q-.406 0-.703.281t-.297.688q0 .438.297.719t.703.281h9.719q.406 0 .688-.281t.281-.719" /></SvgIcon>;
};
const Memo = memo(SvgNewsletter32);
export default Memo;