import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlistCss32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16.2 5.5c2.1-1.9 4.7-2.7 7.2-2.1 2.4.6 4.4 2.3 5.3 4.6 1 2.6.7 5.8-1.1 9.1-2 3.8-5.7 7.6-11.2 11.4l-.4.3-.4-.3c-5.5-3.8-9.2-7.7-11.2-11.4-1.8-3.3-2.1-6.5-1.1-9.1.9-2.4 2.9-4 5.3-4.6 2.5-.5 5.1.2 7.2 2.1l.2.2z" className="wishlist-css-32_svg__fill" /><path d="M21.7 4.7c.4 0 .9.1 1.3.2 2 .5 3.6 1.9 4.3 3.7.9 2.3.5 5-1 7.9C24.4 19.9 21 23.4 16 27c-4.8-3.4-8.4-7.1-10.3-10.6-1.5-2.8-1.9-5.6-1-7.9.7-1.8 2.2-3.2 4.2-3.7.4-.1.8-.1 1.2-.1 1.7 0 3.3.6 4.6 1.8l.2.2L16 7.8l1.1-1.1.2-.2c1.3-1.1 2.9-1.8 4.4-1.8m0-1.5c-2 0-3.9.8-5.5 2.3l-.2.2-.2-.2c-1.7-1.5-3.6-2.3-5.6-2.3-.5 0-1 .1-1.6.2C6.2 4 4.2 5.6 3.3 8c-1 2.6-.7 5.8 1.1 9.1 2 3.7 5.7 7.6 11.2 11.4l.4.3.4-.3c5.5-3.8 9.2-7.6 11.2-11.4 1.8-3.3 2.1-6.5 1.1-9.1-.9-2.3-2.9-4-5.3-4.6-.5-.1-1.1-.2-1.7-.2" className="wishlist-css-32_svg__border" /></SvgIcon>;
};
const Memo = memo(SvgWishlistCss32);
export default Memo;