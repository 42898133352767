import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgLogo = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon viewBox="0 0 477.6 135.6" sx={{
    ...sx
  }} {...other}><path fill="#1E4A5E" d="M216.7 49.2h-7.3c-4.6 0-7.2 2.6-7.2 7.2v35.1c0 13.7-5 17.5-16.7 17.5s-16.7-3.8-16.7-17.5V56.4c0-4.6-2.6-7.2-7.2-7.2h-7.3c-4.6 0-7.2 2.6-7.2 7.2v34.7c0 28 14.1 36.1 38.3 36.1 24.2 0 38.3-8.1 38.3-36.1V56.4c.2-4.6-2.4-7.2-7-7.2m59.7-1.4c-26.2 0-39.6 14.4-39.6 39.7 0 26.8 15.8 39.7 39.4 39.7 7.3 0 15.3-1.2 23.7-3.4 4.1-1.1 6.1-3.8 6.1-8v-2.3c0-5.1-3-7.2-8.1-6.1-6.7 1.6-12.8 2.5-17.8 2.5-10.4 0-17.5-3.1-20.3-11.8l33-3.4c14-1.5 18.6-6.1 18.6-17 0-19.6-13-29.9-35-29.9m9.2 31.7-26.9 2.7c1.2-13.2 6.9-18.1 17.8-18.1 10 0 14.5 4 14.5 11.4-.1 2.7-.9 3.6-5.4 4m54.9-62.7h-7.7c-4.6 0-7.2 2.6-7.2 7.2v94.7c0 4.6 2.6 7.2 7.2 7.2h7.7c4.6 0 7.2-2.6 7.2-7.2V23.9c0-4.6-2.6-7.1-7.2-7.1m40.5 0h-7.7c-4.6 0-7.2 2.6-7.2 7.2v94.7c0 4.6 2.6 7.2 7.2 7.2h7.7c4.6 0 7.2-2.6 7.2-7.2V23.9c-.1-4.6-2.6-7.1-7.2-7.1m96.6 60.8c0-19.5-13-29.8-35-29.8-26.2 0-39.6 14.4-39.6 39.7 0 26.8 15.8 39.7 39.4 39.7 7.3 0 15.3-1.2 23.7-3.4 4.1-1.1 6.1-3.8 6.1-8v-2.3c0-5.1-3-7.2-8.1-6.1-6.7 1.6-12.8 2.5-17.8 2.5-10.4 0-17.5-3.1-20.3-11.8l33-3.4c14-1.6 18.6-6.2 18.6-17.1m-25.9 1.9-26.9 2.7c1.2-13.2 6.9-18.1 17.8-18.1 10 0 14.5 4 14.5 11.4 0 2.7-.9 3.6-5.4 4M67.8 0C49.7 0 32.7 7.1 19.9 19.9 7.1 32.7 0 49.7 0 67.8c0 18.1 7.1 35.1 19.9 47.9 12.8 12.8 29.9 19.9 47.8 19.9 2.7 0 5.5-.2 8.2-.5 6.6-.8 11.2-6.7 10.5-13.3-.8-6.6-6.7-11.2-13.3-10.5-13.5 1.6-26.7-3-36.3-12.5-8.3-8.3-12.8-19.3-12.8-31s4.6-22.7 12.8-31c8.3-8.3 19.3-12.8 31-12.8s22.7 4.6 31 12.8c9.5 9.5 14 22.5 12.6 35.8-.7 6.6 4 12.5 10.6 13.2 6.6.7 12.5-4 13.2-10.6 2.2-20.6-4.8-40.7-19.4-55.3C102.9 7.1 85.9 0 67.8 0" /><path fill="#1E4A5E" d="M76.3 59.6c-4.7-4.7-12.2-4.7-16.9 0-4.7 4.7-4.7 12.2 0 16.9l46.9 46.9c2.3 2.3 5.4 3.5 8.5 3.5 3.1 0 6.1-1.2 8.5-3.5 4.7-4.7 4.7-12.2 0-16.9z" /></SvgIcon>;
};
const Memo = memo(SvgLogo);
export default Memo;