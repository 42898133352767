import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlist32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M16.716 5.541c2.077-1.93 4.694-2.663 7.158-2.117 2.407.534 4.391 2.227 5.313 4.614 1.003 2.607.684 5.791-1.071 9.138-1.972 3.769-5.688 7.644-11.189 11.441l-.426.294-.426-.294c-5.494-3.792-9.21-7.669-11.189-11.44-1.756-3.347-2.077-6.535-1.071-9.139.922-2.388 2.904-4.08 5.313-4.613 2.467-.546 5.08.186 7.157 2.117l.215.2.215-.2zm.967 1.174-.202.202-.127.126-.106.104c-.119.123-.15.156-.172.182l-.574.683-.574-.683a6.722 6.722 0 0 0-.405-.412l-.202-.202a1.807 1.807 0 0 1-.115-.126c-1.717-1.563-3.798-2.132-5.752-1.7-1.92.425-3.497 1.772-4.238 3.689-.841 2.177-.565 4.919 1 7.902 1.805 3.441 5.215 7.039 10.287 10.606 5.078-3.571 8.488-7.168 10.287-10.606 1.564-2.983 1.839-5.722 1-7.902-.74-1.916-2.32-3.264-4.238-3.689-1.951-.433-4.035.137-5.74 1.685a1.81 1.81 0 0 1-.126.141z" /></SvgIcon>;
};
const Memo = memo(SvgWishlist32);
export default Memo;